import Link from 'next/link'
import React from 'react'
import { EntityTypename } from 'src/constants'

const entitySlugs = {
	[EntityTypename.PROJECT]: 'project',
	[EntityTypename.BUILDING]: 'building',
	[EntityTypename.HOUSING_UNIT]: 'housingUnit',
	[EntityTypename.HOUSING_UNIT_TEMPLATE]: 'housingUnitTemplate',
	[EntityTypename.USER]: 'user',
} as const

export function EntityLink({
	id,
	type,
	children,
	className,
}: {
	id: string
	type: EntityTypename
	children: React.ReactNode
	className?: string
}) {
	const slug = entitySlugs[type]
	return (
		<Link href={`/${slug}/[id]`} as={`/${slug}/${id}`}>
			<a className={className}>{children}</a>
		</Link>
	)
}
