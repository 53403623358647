import { $ } from 'generated/content'
import { useUserContent } from 'hooks/useContember'
import { useDuplicateHousingUnitTemplate } from 'hooks/useDuplicateHousingUnitTemplate'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useQuery } from 'react-query'
import { EntityTypename, ROUTES } from 'src/constants'
import { cn } from 'utils/cn'
import s from './Breadcrumbs.module.sass'
import { Dropdown } from './Dropdown'
import { EntityLink } from './EntityLink'
import { Icon } from './Icon'
import { RouteLink } from './NavLink'
import { RequireAdmin } from './RequireRole'

type Info = { id: string; name?: string }

interface Props {
	project?: Info
	building?: Info
	housingUnit?: Info
	housingUnitTemplate?: Info
	user?: Info
	actions?: React.ReactNode
	view?: React.ReactNode
}

export const StaticBreadcrumbs = React.memo(function StaticBreadcrumbs({
	project,
	building,
	housingUnit,
	housingUnitTemplate,
	user,
	actions,
	view,
}: Props) {
	const breadcrumbs = React.useMemo(() => {
		return [
			{ item: project, type: EntityTypename.PROJECT },
			{ item: building, type: EntityTypename.BUILDING },
			{ item: user, type: EntityTypename.USER },
			{ item: housingUnit, type: EntityTypename.HOUSING_UNIT },
			{ item: housingUnitTemplate, type: EntityTypename.HOUSING_UNIT_TEMPLATE },
		].filter((x) => !!x.item) as { item: Info; type: EntityTypename }[]
	}, [project, building, user, housingUnit, housingUnitTemplate])

	return (
		<nav className={s.Breadcrumbs} aria-label="breadcrumb">
			<ol className="breadcrumb">
				<li className={cn('breadcrumb-item')}>
					<Link href={`/`}>
						<a className={s.Home}>
							<Icon name="home" />
						</a>
					</Link>
				</li>
				{breadcrumbs.map((bc, i) => {
					const isCurrent = i === breadcrumbs.length - 1 && !view
					return (
						<li
							key={i}
							className={cn('breadcrumb-item', isCurrent && 'active')}
							aria-current={isCurrent ? 'page' : undefined}>
							{isCurrent ? (
								<span>
									{bc.item.name}{' '}
									<RequireAdmin>
										<BreadcrumbsEntityActions type={bc.type} id={bc.item.id} />
									</RequireAdmin>
								</span>
							) : (
								<span>
									<EntityLink type={bc.type} id={bc.item.id}>
										{bc.item.name}
									</EntityLink>{' '}
									<RequireAdmin>
										<BreadcrumbsEntityActions type={bc.type} id={bc.item.id} />
									</RequireAdmin>
								</span>
							)}
						</li>
					)
				})}
				{view && (
					<li className={cn('breadcrumb-item', 'active')} aria-current={'page'}>
						{view}
					</li>
				)}
			</ol>
			<div className={s.Actions}>{actions}</div>
		</nav>
	)
})

function useEntityBreadcrumbs(type?: EntityTypename, id?: string) {
	const content = useUserContent()

	return useQuery<Props>(['useEntityBreadcrumbs', type, id], async () => {
		if (!type || !id) {
			return {}
		}
		switch (type) {
			case EntityTypename.PROJECT: {
				const response = (
					await content.query(
						{
							getProject: [{ by: { id: $`id` } }, { id: true, name: true }],
						},
						{ id }
					)
				).getProject
				return {
					project: response,
				}
			}
			case EntityTypename.BUILDING: {
				const response = (
					await content.query(
						{
							getBuilding: [
								{ by: { id: $`id` } },
								{
									id: true,
									name: true,
									project: [{}, { id: true, name: true }],
								},
							],
						},
						{ id }
					)
				).getBuilding
				return {
					building: response,
					project: response?.project,
				}
			}
			case EntityTypename.HOUSING_UNIT: {
				const response = (
					await content.query(
						{
							getHousingUnit: [
								{ by: { id: $`id` } },
								{
									id: true,
									name: true,

									building: [
										{},
										{
											id: true,
											name: true,
											project: [{}, { id: true, name: true }],
										},
									],
								},
							],
						},
						{ id }
					)
				).getHousingUnit
				return {
					housingUnit: response,
					building: response?.building,
					project: response?.building?.project,
				}
			}
			case EntityTypename.HOUSING_UNIT_TEMPLATE: {
				const response = (
					await content.query(
						{
							getHousingUnitTemplate: [
								{ by: { id: $`id` } },
								{ id: true, name: true },
							],
						},
						{ id }
					)
				).getHousingUnitTemplate
				return {
					housingUnitTemplate: response
						? {
								id: response.id,
								name: `Šablona ${response.name}`,
						  }
						: undefined,
				}
			}
			case EntityTypename.USER: {
				const response = (
					await content.query(
						{ getUser: [{ by: { id: $`id` } }, { id: true, name: true }] },
						{ id }
					)
				).getUser
				return {
					user: response,
				}
			}
		}
		throw new Error(`Unknown type for Breadcrumbs ${type}`)
	})
}

export const Breadcrumbs = React.memo(function Breadcrumbs(
	props: Props & {
		type?: EntityTypename
		id?: string
	}
) {
	const { type, id, ...restProps } = props
	const breadcrumbsProps = useEntityBreadcrumbs(type, id)

	return <StaticBreadcrumbs {...breadcrumbsProps.data} {...restProps} />
})

function HousingUnitTemplateActions(props: { id: string }) {
	const router = useRouter()
	const [duplicate, duplication] = useDuplicateHousingUnitTemplate(props.id)
	return (
		<div className="list-group">
			<RouteLink route={ROUTES.HOUSING_UNIT_TEMPLATE(props.id, 'edit')}>
				<a className="list-group-item list-group-item-action">
					<Icon name="settings" /> Upravit
				</a>
			</RouteLink>
			<RouteLink route={ROUTES.HOUSING_UNIT_TEMPLATE(props.id, 'edit-configurator')}>
				<a className="list-group-item list-group-item-action">
					<Icon name="settings" /> Konfigurátor
				</a>
			</RouteLink>
			<button
				disabled={duplication.isLoading}
				type="button"
				className="list-group-item list-group-item-action"
				onClick={async () => {
					const duplicated = await duplicate()
					if (
						duplicated?.duplicateHousingUnitTemplate.ok &&
						duplicated.duplicateHousingUnitTemplate?.housingUnitTemplate?.id
					) {
						const r = ROUTES.HOUSING_UNIT_TEMPLATE(
							duplicated.duplicateHousingUnitTemplate.housingUnitTemplate.id
						)
						router.push(r.href, r.as)
					} else {
						console.error(duplicated?.duplicateHousingUnitTemplate)
						alert('Něco se nezdařilo')
					}
				}}>
				<Icon name="duplicate" /> Duplikovat
			</button>
		</div>
	)
}

function HousingUnitActions(props: { id: string }) {
	return (
		<div className="list-group">
			<RouteLink route={ROUTES.HOUSING_UNIT(props.id, 'edit')}>
				<a className="list-group-item list-group-item-action">
					<Icon name="settings" /> Upravit
				</a>
			</RouteLink>
			<RouteLink route={ROUTES.HOUSING_UNIT(props.id, 'edit-configurator')}>
				<a className="list-group-item list-group-item-action">
					<Icon name="settings" /> Konfigurátor
				</a>
			</RouteLink>
		</div>
	)
}

export const BreadcrumbsEntityActions = React.memo(function BreadcrumbsEntityActions({
	type,
	id,
}: {
	type: EntityTypename
	id: string
}) {
	let content: null | React.ReactNode = null

	switch (type) {
		case EntityTypename.HOUSING_UNIT_TEMPLATE:
			content = <HousingUnitTemplateActions id={id} />
			break
		case EntityTypename.HOUSING_UNIT:
			content = <HousingUnitActions id={id} />
			break
	}

	if (!content) {
		return null
	}
	return <Dropdown label={<Icon name="caret" />} content={content} placement="bottom-end" />
})
