import React from 'react'
import { Role } from 'src/constants'
import {
	useIsAdmin,
	useIsInOverridableRole,
	useIsInRole,
	useIsOverridableAdmin,
	useIsOverridablePrivileged,
} from 'src/contexts/authContext'

export const RequireRealRole = React.memo(function RequireRealRole(
	props: React.PropsWithChildren<{ role: Role }>
) {
	const authorized = useIsInRole(props.role)
	return <>{authorized ? props.children : null}</>
})

export const RequireRole = React.memo(function RequireRole(
	props: React.PropsWithChildren<{ role: Role }>
) {
	const authorized = useIsInOverridableRole(props.role)
	return <>{authorized ? props.children : null}</>
})

export const RequirePrivileged = React.memo(function RequirePrivileged(
	props: React.PropsWithChildren<unknown>
) {
	const authorized = useIsOverridablePrivileged()
	return <>{authorized ? props.children : null}</>
})

export const RequireAdmin = React.memo(function RequireAdmin(
	props: React.PropsWithChildren<unknown>
) {
	const authorized = useIsOverridableAdmin()
	return <>{authorized ? props.children : null}</>
})

export const RequireRealAdmin = React.memo(function RequireAdmin(
	props: React.PropsWithChildren<unknown>
) {
	const authorized = useIsAdmin()
	return <>{authorized ? props.children : null}</>
})
