import { useMutation } from 'react-query'
import { useUserContent, useUserGateway } from './useContember'

export function useDuplicateHousingUnitTemplate(id: string) {
	const content = useUserContent()
	const gateway = useUserGateway()
	return useMutation(async () => {
		const oldName = await content.query({
			getHousingUnitTemplate: [{ by: { id } }, { name: true }],
		})
		const targetName = prompt('Nové jméno šablony', oldName.getHousingUnitTemplate?.name)

		if (targetName) {
			return gateway.mutation({
				duplicateHousingUnitTemplate: [
					{ housingUnitTemplateId: id, targetName },
					{
						ok: true,
						errorDeveloperDescription: true,
						housingUnitTemplate: { id: true },
					},
				],
			})
		}
	})
}
