import React from 'react'
import s from './PageTitle.module.sass'

export function PageTitle({
	children,
	actions,
}: {
	children: React.ReactNode
	actions?: React.ReactNode
}) {
	return (
		<div className="d-flex align-items-baseline">
			<div>
				<h1 className={s.Title}>{children}</h1>
			</div>
			<div className="ml-auto">{actions}</div>
		</div>
	)
}
